<template>
<div>
    <!-- Top Domain Details -->
    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="domain-info select-review mb-8">
                <div class="domain-select">
                    <v-select v-model="locationSelected" name="Location" class="mt-1" placeholder="Location" :options="locationOptions" :reduce="locationOptions => locationOptions.id" label="name" :clearable="false" />
                </div>
                <!-- <select-filter
            labelname="LocationName"
            :options="locationOptions"
            optionsName=""
            :value="locationSelected"
            @input="(newDomain) => {locationSelected = newDomain}"
          ></select-filter>-->
                <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-refresh-cw" class="refresh-btn" @click="locationSelected = 'all'"></vs-button>
                <date-range-picker :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            " @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "></date-range-picker>
                <vs-button type="filled" @click="updateData()" class="bg-actionbutton">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
            </div>
        </div>
    </div>
    <!-- Card -->
    <div class="vx-row">
        <div class="vx-col lg:w-1/2 col-cus-12 mb-0 lg:mb-8">
            <div class="vx-row h-full">
                <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8 lg:mb-0">
                    <vx-card class="bg-secondary-gradient h-full rating-card">
                        <div class="flex justify-between items-center w-full">
                            <h4>Avg Rating</h4>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-primary"></feather-icon>
                                <span class="num-value">{{
                    averageData ? averageData.StatChangePercent : ""
                  }}</span>
                            </div>
                        </div>
                        <div class="rating-value">
                            <h2 class="text-white">
                                {{ averageData ? averageData.StatEndValue : "" }}
                            </h2>
                            <feather-icon icon="StarIcon" class="text-white"></feather-icon>
                        </div>
                        <p class="text-white">
                            Previous Period
                            {{ averageData ? averageData.StatStartValue : "" }}
                        </p>
                    </vx-card>
                </div>
                <div class="vx-col lg:w-1/2 md:w-1/2 w-full mb-8 lg:mb-0">
                    <vx-card class="h-full">
                        <div class="card-title">
                            <h2>Review By Rating</h2>
                        </div>
                        <vue-apex-charts type="pie" height="300" :options="pieChart.chartOptions" :series="pieChart.series"></vue-apex-charts>
                    </vx-card>
                </div>
            </div>
        </div>
        <!--<div class="vx-col lg:w-1/2   mb-8 col-cus-12">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Average Rating</h2>
          </div>
          <vue-apex-charts
            type="area"
            height="261"
            :options="lineAreaChartSpline.chartOptions"
            :series="lineAreaChartSpline.series"
          ></vue-apex-charts>
        </vx-card>
      </div>-->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8">
            <vx-card class="total-review h-full">
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-4">
                        <div class="review-part bg-first-gradient">
                            <feather-icon icon="CheckCircleIcon" class="text-white"></feather-icon>
                            <div class="review-title pl-6">
                                <h4 class="text-white">
                                    <span class="review-no text-white">{{
                      totalReviewData ? totalReviewData.StatEndValue : ""
                    }}</span>Total Reviews
                                </h4>
                                <p class="text-white">
                                    Previous Period
                                    {{ totalReviewData ? totalReviewData.StatStartValue : "" }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-white"></feather-icon>
                                <span class="num-value text-white">{{
                    totalReviewData ? totalReviewData.StatChangePercent : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-4 sm:mt-0 mt-4">
                        <div class="review-part">
                            <feather-icon icon="ThumbsUpIcon" class="text-primary"></feather-icon>
                            <div class="review-title pl-6">
                                <h4>
                                    <span class="review-no text-primary">{{
                      positiveReviewsData
                        ? positiveReviewsData.StatEndValue
                        : ""
                    }}</span>Positive Reviews
                                </h4>
                                <p>
                                    Previous Period
                                    {{
                      positiveReviewsData
                        ? positiveReviewsData.StatStartValue
                        : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowDownIcon" class="text-danger"></feather-icon>
                                <span class="num-value text-danger">{{
                    positiveReviewsData
                      ? positiveReviewsData.StatChangePercent
                      : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-4 sm:mb-0 mb-4">
                        <div class="review-part">
                            <feather-icon icon="MehIcon" class="text-primary"></feather-icon>
                            <div class="review-title pl-6">
                                <h4>
                                    <span class="review-no text-primary">{{
                      neturalReviewsData ? neturalReviewsData.StatEndValue : ""
                    }}</span>Neutral Reviews
                                </h4>
                                <p>
                                    Previous Period
                                    {{
                      neturalReviewsData
                        ? neturalReviewsData.StatStartValue
                        : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowDownIcon" class="text-danger"></feather-icon>
                                <span class="num-value text-danger">{{
                    neturalReviewsData
                      ? neturalReviewsData.StatChangePercent
                      : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-4">
                        <div class="review-part">
                            <feather-icon icon="ThumbsDownIcon" class="text-primary"></feather-icon>
                            <div class="review-title pl-6">
                                <h4>
                                    <span class="review-no text-primary">{{
                      negativeReviewsData
                        ? negativeReviewsData.StatEndValue
                        : ""
                    }}</span>Negative Reviews
                                </h4>
                                <p>
                                    Previous Period
                                    {{
                      negativeReviewsData
                        ? negativeReviewsData.StatStartValue
                        : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowDownIcon" class="text-danger"></feather-icon>
                                <span class="num-value text-danger">{{
                    negativeReviewsData
                      ? negativeReviewsData.StatChangePercent
                      : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
    <!-- Visitor -->
    <div class="vx-row">
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8">
            <vx-card class="total-review h-full">
                <div class="vx-row">
                    <div class="vx-col w-full mb-4">
                        <div class="review-part bg-first-gradient">
                            <feather-icon icon="MailIcon" class="text-white"></feather-icon>
                            <div class="review-title pl-6">
                                <h4 class="text-white">
                                    <span class="review-no text-white">{{
                      totalEmailRequest ? totalEmailRequest.StatEndValue : ""
                    }}</span>Email Requests
                                </h4>
                                <p class="text-white">
                                    Previous Period
                                    {{
                      totalEmailRequest ? totalEmailRequest.StatStartValue : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowUpIcon" class="text-white"></feather-icon>
                                <span class="num-value text-white">{{
                    totalEmailRequest ? totalEmailRequest.StatChangePercent : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-4 sm:mb-0 mb-4">
                        <div class="review-part">
                            <feather-icon icon="FileTextIcon" class="text-primary"></feather-icon>
                            <div class="review-title pl-6">
                                <h4>
                                    <span class="review-no text-primary">{{
                      totalEmailRequestOpend
                        ? totalEmailRequestOpend.StatEndValue
                        : ""
                    }}</span>Email Requests Opened
                                </h4>
                                <p>
                                    Previous Period
                                    {{
                      totalEmailRequestOpend
                        ? totalEmailRequestOpend.StatStartValue
                        : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowDownIcon" class="text-danger"></feather-icon>
                                <span class="num-value text-danger">{{
                    totalEmailRequestOpend
                      ? totalEmailRequestOpend.StatChangePercent
                      : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-4 sm:mb-0 mb-4">
                        <div class="review-part">
                            <feather-icon icon="MousePointerIcon" class="text-primary"></feather-icon>
                            <div class="review-title pl-6">
                                <h4>
                                    <span class="review-no text-primary">{{
                      totalEmailRequestClicked
                        ? totalEmailRequestClicked.StatEndValue
                        : ""
                    }}</span>Email Requests Clicked
                                </h4>
                                <p>
                                    Previous Period
                                    {{
                      totalEmailRequestClicked
                        ? totalEmailRequestClicked.StatStartValue
                        : ""
                    }}
                                </p>
                            </div>
                            <div class="up-down-value">
                                <feather-icon icon="ArrowDownIcon" class="text-danger"></feather-icon>
                                <span class="num-value text-danger">{{
                    totalEmailRequestClicked
                      ? totalEmailRequestClicked.StatChangePercent
                      : ""
                  }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8">
            <single-type-multi-column-chart
                :chartTitle="'Reviews By Source'"
                :chartType="'bar'"
                :chartHeight="'240'"

                :seriesNameOne="'Review Count'"
                :seriesDataOne="barChartSeriesDataOne"

                :optionsXaxis="barChartOptionsXaxis"
            ></single-type-multi-column-chart>
        </div>
    </div>
</div>
</template>

<script>
import vSelect from "vue-select";
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import SingleCard from "../../components/SingleCard";
/* chart */
import VueApexCharts from "vue-apexcharts";
import SingleTypeMultiColumnChart from '../../components/charts/SingleTypeMultiColumnChart.vue';

export default {
    components: {
        VxCard,
        SelectFilter,
        DateRangePicker,
        SingleCard,
        VueApexCharts,
        vSelect,
        SingleTypeMultiColumnChart
    },
    data() {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        return {
            /* Domain Filter */
            locationSelected: "all",
            //Domain dropdown options
            locationOptions: [],
            //From Date
            fromDate: null,
            //To Date
            toDate: null,

            averageData: null,
            totalReviewData: null,
            positiveReviewsData: null,
            neturalReviewsData: null,
            negativeReviewsData: null,
            totalEmailRequestOpend: null,
            totalEmailRequestClicked: null,

            totalEmailRequest: null,
            /* Bar Chart */
            barChartSeriesDataOne: [],
            barChartOptionsXaxis: [],
            
            /* Pie Chart */
            pieChart: {
                series: [],
                chartOptions: {
                    labels: [],
                    colors: [
                        token[uid].themeDetail.tempChartColor1,
                        token[uid].themeDetail.tempChartColor2,
                        token[uid].themeDetail.tempChartColor3,
                        token[uid].themeDetail.tempChartColor4,
                        token[uid].themeDetail.tempChartColor5
                    ],
                    legend: {
                        position: "bottom"
                    }
                }
            }
            /* Line Chart */
        };
    },
    created() {
        this.getLocationList();
        this.setFromDate();
        this.setToDate();
        this.getRatingsData();
        this.getTotalReviews();
        this.getPositiveReviews();
        this.getNeturalReviews();
        this.getNegativeReviews();
        this.getReviewBySource();
        this.getReviewByRating();
        this.getAverageRating();
        this.getTotalEmail();
        this.getTotalEmailOpened();
        this.getTotalEmailClicked();
    },
    methods: {
        updateData() {
            this.getRatingsData();
            this.getTotalReviews();
            this.getPositiveReviews();
            this.getNeturalReviews();
            this.getNegativeReviews();
            this.getReviewBySource();
            this.getReviewByRating();
            this.getAverageRating();
            this.getTotalEmail();
            this.getTotalEmailOpened();
            this.getTotalEmailClicked();
        },
        async getLocationList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ReviewsGetStarted/GetLocationList")
                .then(response => {
                    let data = response.data;
                    if (data.length <= 0) {
                        this.$router.push(
                            "/reputation-management/getting-started/uid/" +
                            this.$route.params.id +
                            "?setLocation=true"
                        );
                    }
                    let location = [];
                    location.push({
                        id: "all",
                        name: "All Locations"
                    });
                    data.forEach(element => {
                        location.push({
                            id: element.LocationId,
                            name: element.LocationName
                        });
                    });
                    this.locationOptions = location;

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getRatingsData() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetAverageReviewRating", getRatings)
                .then(response => {
                    let data = response.data;
                    this.averageData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getTotalReviews() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalReviews", getRatings)
                .then(response => {
                    let data = response.data;
                    this.totalReviewData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getPositiveReviews() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalPositiveReviews", getRatings)
                .then(response => {
                    let data = response.data;
                    this.positiveReviewsData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getNeturalReviews() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalNeutralReviews", getRatings)
                .then(response => {
                    let data = response.data;
                    this.neturalReviewsData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getNegativeReviews() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalNegativeReviews", getRatings)
                .then(response => {
                    let data = response.data;
                    this.negativeReviewsData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getReviewBySource() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetReviewsBySource", getRatings)
                .then(response => {
                    let data = response.data;
                    let x = [];
                    let firstGroup = [];
                    data.forEach(element => {
                        x.push(element.x);
                        firstGroup.push(element.FirstGroup);
                    });

                    this.barChartSeriesDataOne = firstGroup
                    this.barChartOptionsXaxis = x

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getReviewByRating() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetReviewsByRating", getRatings)
                .then(response => {
                    let data = response.data;
                    let x = [];
                    let firstGroup = [];
                    data.forEach(element => {
                        x.push(element.x);
                        firstGroup.push(element.FirstGroup);
                    });
                    this.pieChart.chartOptions = {
                        ...this.pieChart.chartOptions,
                        ...{
                            labels: x
                        }
                    };
                    this.pieChart.series = firstGroup;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getAverageRating() {
            this.$vs.loading();
            let getRatings = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetReviewScoreOverTime", getRatings)
                .then(response => {
                    let data = response.data;

                    let x = [];
                    let firstGroup = [];
                    data.forEach(element => {
                        // let dd = new Date(element.x);
                        // let year = dd.getFullYear();
                        // let month = dd.getMonth() + 1;
                        // let day = dd.getDate();
                        // let date = month + "/" + day + "/" + year;
                        // x.push(date);
                        x.push(element.x);
                        firstGroup.push(element.FirstGroup);
                    });

                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getTotalEmail() {
            this.$vs.loading();
            let totalEmails = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalEmailRequests", totalEmails)
                .then(response => {
                    let data = response.data;
                    this.totalEmailRequest = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getTotalEmailOpened() {
            this.$vs.loading();
            let totalEmails = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalEmailRequestsOpened", totalEmails)
                .then(response => {
                    let data = response.data;
                    this.totalEmailRequestOpend = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getTotalEmailClicked() {
            this.$vs.loading();
            let totalEmails = {
                CompanyId: this.locationSelected,
                Date_Start: this.fromDate,
                Date_End: this.toDate
            };
            await this.axios
                .post("/ws/ReviewDashboard/GetTotalEmailRequestsClicked", totalEmails)
                .then(response => {
                    let data = response.data;
                    this.totalEmailRequestClicked = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        }
    }
};
</script>
